// ######################################## //
// ## COLORS.SCSS ## //
// ######################################## //

// Black (Gray) Color Shades
$black: #000000;
$gray-1: #111111;
$gray-2: #222222;
$gray-3: #333333;
$gray-4: #444444;
$gray-5: #555555;
$gray-6: #666666;
$gray-7: #777777;
$gray-8: #888888;
$gray-9: #999999;
$gray-a: #aaaaaa;
$gray-b: #bbbbbb;
$gray-c: #cccccc;
$gray-d: #dddddd;
$gray-e: #eeeeee;
$white: #ffffff;
$gray-f5: #f5f5f5;


// Color Shades (Theme Based - Xclusive)
$theme-primary: #37ac72;
$theme-secondary:  #6CA7ED;
$theme-light-orange:#f7c06e;
$theme-primary-dark: #002c61;
$theme-primary-bg: #f7fafe;
$theme-primary-text: #212b36;
$theme-primary-shadow: #e4edfa;
$theme-info: #9c6dff;
$theme-info-bg: #F7F4FE;
$theme-warning: #FF8238;
$theme-warning-bg: #FFFAF7;
$theme-success: #4AC14F;
$theme-success-bg: #F2FFF4;
$theme-danger: #f96464;
$theme-danger-bg: #FFF5F5;
$theme-yellow: #E3C040;
$theme-yellow-bg: #FDFCF6;
$theme-cyan: #35D2CC;
$theme-cyan-bg: #F6FDFD;
$theme-pink:#C83375;
$theme-pink-bg: #FFF8F9;
$theme-gray: #919DA8;
$theme-link: #004b8f;
$body-bg: #ECF1F4;
$theme-fb: #475993;
$theme-geekblue: #2f54eb;
$light-red:#E66793;
$light-cyan:#4BBBCE;