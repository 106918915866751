// ######################################## //
// ## LOGIN.SCSS ## //
// ######################################## //

// login-section
.login-section {
  
  // row height
  .row-height {
    height: 100vh;
  }
  
  // card-login
  .card-login {
    background: $white;
    padding: 2rem;
    border-radius: 0.5rem;
  }
  
  .ant-row:last-child {
    margin-bottom: 0;
  }
  
  .login-form-forgot {
    float: right;
  }
}

// signup-form
.signup-form {
  width: 100%;
  
  .anticon-phone {
    transform: rotate(135deg);
  }
}