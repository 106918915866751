
// ######################################## //
// ## FOOTER.SCSS ## //
// ######################################## //

// footer-style section
.footer-primary {
  background: $white;
  padding: 13px 50px;
  font-weight: 600;
  color: $gray-8;
  font-size: 0.8rem;
}

