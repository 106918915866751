
// ######################################## //
// ## HEADER.SCSS ## //
// ######################################## //\



// Sidebar Panel
.sidebar-menu {
  
  position: absolute; 
  left: 0; 
  margin-top: 10px;
  z-index: 2;
  max-width: 256px;
  min-height: 100vh;
  // position: 'absolute', left: 0, maxWidth: '256px', minHeight: '100vh'
}
.collapse-button {
margin : 0.5%;


}

// .sidebar-panel {
//     overflow: hidden;
//     overflow-y: auto;
//     height: 100vh;
//     padding-top: 20px;
//     position: fixed;
//     left: 0;
//     background: $white;
//     z-index: 3;
// }


// // Sidebar Menu section
// .sidebar-section {
//     z-index: 3;
  
//     .logo {
//       padding: 1.5rem 0;
//     }
  
//     .ant-menu-light {
//       border:none;
//       height: 10vh;
//     }

//     .ant-menu-item {

//       height: 8vh;

//       &:after {
//         left: 0;
//         border-left: 3px solid $theme-secondary;
//         border-right: none;
//       }

      
      
//       &:hover, &:focus {
//         background-color: rgba($theme-secondary, 0.2) !important;
//         color: darken($theme-secondary, 5%)
//       }
//     }
    
//     .ant-menu-item-selected {
//       background-color: rgba($theme-secondary, 0.2) !important;
//       color: darken($theme-secondary, 5%)
//     }
    
//   }