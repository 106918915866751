// ********************************* //
// ********** GRID LAYOUT ********** //
// ********************************* //

// ----------------------------------
// Media Query Mixin For container
// ----------------------------------
$xs-width: 576px;
$sm-width: 768px;
$md-width: 992px;
$lg-width: 1200px;

// Extra small Device
@mixin xs {
	@media (min-width: #{$xs-width}) {
		@content;
	}
}

// Small Device
@mixin sm {
	@media (min-width: #{$sm-width}) {
		@content;
	}
}

// Medium Device
@mixin md {
	@media (min-width: #{$md-width}) {
		@content;
	}
}

// Large Screen
@mixin lg {
	@media (min-width: #{$lg-width}) {
		@content;
	}
}


// Primary Width Container
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

// Extra small Device
@include xs {
  .container {
    max-width: 540px;
  }
}

// Small Device
@include sm {
  .container {
    max-width: 720px;
  }
}

// Medium Device
@include md {
  .container {
    max-width: 960px;
  }
}

// Large Device
@include lg {
  .container {
    max-width: 1140px;
  }
}

// Full Width Container
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}