// ######################################## //
// ## CHART.SCSS ## //
// ######################################## //

// login-section
.chart-header-sec {

    .recharts-wrapper {
        margin: 0;
    }

    .status-txt {
        font-size: 1.2rem;
    }

    .bar-chart-sec {

        .title {
            text-transform: uppercase;
        }
    }
}