
// ######################################## //
// ## HEADER.SCSS ## //
// ######################################## //\

// Sidebar Panel
.secondary-sidebar-panel {
    overflow: hidden;
    overflow-y: auto;
    min-height: 40vh;
    position: fixed;
    margin-top:10px;
    padding-top: 20px;
    z-index: 0;
}


// Sidebar Menu section
.secondary-sidebar-section {
    
    z-index: 0;

    .logo {
      padding: 1.5rem 0;
    }
  
    .ant-menu-light {
      border: none;
      
    }
    
    .ant-menu-item {

      height: 8vh;
      
      
      &:after {
        left: 0;
        border-left: 3px solid $theme-secondary;
        border-right: none;
      }
      
      &:hover, &:focus {
        background-color: rgba($theme-secondary, 0.2) !important;
        color: darken($theme-secondary, 5%)
      }
    }
    
    .ant-menu-item-selected {
      background-color: rgba($theme-secondary, 0.2) !important;
      color: darken($theme-secondary, 5%)
    }
    
  }