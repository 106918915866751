// ######################################## //
// ## BODY.SCSS ## //
// ######################################## //

// Body
body {
  @include ff-primary;
  color: $theme-primary-text;
  letter-spacing: 0.03rem;
  font-family: 'Open Sans', sans-serif;
  -webkit-overflow-scrolling: touch;
  background: $body-bg;

  // Overflow (Hidden)
  &.overflow-hidden {
    overflow: hidden;
  }
}
.ant-notification-notice {
  position: relative;
  margin-bottom: 16px;
  padding: 16px 0px;
  overflow: hidden;
  line-height: 1.5;
  background: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.dynamic-form-col-padding {
  padding: 2px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 78px;
  font-size: 16px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 78px;
  font-size: 14px;
}

.ant-menu-submenu-title {
  font-weight: bold;
}

.ant-form-extra {
  margin: 0;
  padding: 0;
}
.ant-upload-picture-card-wrapper {
  display: block;
}

.row-light {
  background-color: red;
}
.row-dark {
  background-color: blue;
}

.remove-image-icon {
  position: 'absolute' !important;
  top: '50%' !important;
  right: '50%' !important;
}

.ant-form-item-label {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: break-spaces;
  text-align: right;
  vertical-align: middle;
}
.table-action-icon {
  // color: #212b36;
  cursor: pointer;
  margin-right: 10px;
}
// Select Style For Webkit
select {
  // Form Control
  &.form-control {
    -webkit-appearance: none;
    border-radius: 0;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 24 24'><path fill='#999' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;

    // On mobile
    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: hidden;
}

.logo {
  height: 60px;
  color: white;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 70px;
}

.main-container {
  margin: 50px;
  padding: 20px;
  background: rgb(255, 255, 255);
  min-height: 82vh;
}

.go-to-Store-icon {
  margin-left: 1px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.div-right {
  float: right;
}

.link-span {
  cursor: pointer;
}
// Height Primary
.height-primary {
  min-height: calc(100vh - 109px);

  // On mobile
  @include mobile {
    height: auto;
  }
}

.graph-heading {
  color: #1890ff;
  margin-left: 10px;
}

// main-section
.main-section {
  position: relative;

  &:before {
    // background: url('./assets/img/bg/circle2.svg');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 50%;
    background-position: center;
    background-attachment: fixed;

    opacity: 0.04;
    content: '';
  }
}

.bg-illustration {
  position: relative;

  &::before {
    background-image: url('./../../assets/images/background/landing/e/food.png');
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-position: left;
    background-size: 60%;
    content: '';
    background-repeat: no-repeat;

    @include mobile {
      background-size: cover;
      background-position: 40% center;
    }
  }
}

// overflow-hidden
.overflow-hidden {
  overflow: hidden;

  @include mobile {
    overflow: auto;
  }
}

// main layout section
.layout {
  background: $body-bg;
}

// Large Screen 100%
.lg-col-100 {
  @include desktop-lg {
    width: 80%;
  }
}

// Gradient Color
.bggradient1 {
  background: linear-gradient(135deg, #17ead9 0%, #6078ea 100%);
}
.bggradient2 {
  background: linear-gradient(135deg, #fad961 0%, #f76b1c 100%);
}
.bggradient3 {
  background: linear-gradient(135deg, #42e695 0%, #3bb2b8 100%);
}

.content-wrapper {
  min-height: calc(100vh - 109px);
  margin-left: 15%;
}

.main-content {
  min-height: 360;
  padding: 24;
  margin: 24;
  background: '#fff';
}
.bg-container {
  background-color: white;
  border-radius: 10px;
  padding: 24;
  min-height: 360;
}

.breadcum-container {
  width: 95%;
}

.padding-md {
  padding: 20px;
}

// Ant Card
.ant-card {
  border: none;
  border-radius: $radius-primary;
  overflow: hidden;
}

.add-modal-container {
  width: 420px !important;
}

.action-buttons-container {
  button {
    margin: 10px 10px 0 10px;
  }
}

.profile-details-container {
  padding: 20px;

  .profile-card {
    .ant-card-body {
      padding-top: 10px;
    }

    .attribute-row {
      padding: 5px 0;
    }

    .attribute-name {
      text-transform: capitalize;
    }
  }
}

.families-card-container {
  padding: 20px;

  .families-card {
    .ant-card-body {
      padding-top: 10px;
    }
    .ant-list-item {
      padding: 5px;
    }

    .ant-btn-link {
      padding: 0;
    }
  }
}

.ant-tag {
  font-size: 10px !important;
  text-transform: uppercase;
}

.ant-table-small {
  border: none;
}

.ant-table-small > .ant-table-content > .ant-table-body {
  margin-left: 0;
  margin-right: 0;
}

.addonDetails .ant-table-tbody td {
  font-size: 12px !important;
}

.ant-btn {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}

.ant-typography mark {
  text-transform: uppercase;
  padding: 3px 5px;
  font-size: 0.8em;
}

.btn-success {
  border-color: $theme-primary;
  color: #fff;
  background-color: $theme-primary;
}

.btn-success:hover {
  border-color: $theme-primary;
  background-color: #fff;
  color: $theme-primary;
}

.btn-danger {
  border-color: $theme-danger;
  color: #fff;
  background-color: $theme-danger;
}

.btn-danger:hover {
  border-color: $theme-danger;
  background-color: #fff;
  color: $theme-danger;
}

.btn-warning {
  border-color: $theme-warning;
  color: #fff;
  background-color: $theme-warning;
}

.btn-warning:hover {
  border-color: $theme-warning;
  background-color: #fff;
  color: $theme-warning;
}

.btn-info {
  border-color: $theme-info;
  color: #fff;
  background-color: $theme-info;
}

.btn-info:hover {
  border-color: $theme-info;
  background-color: #fff;
  color: $theme-info;
}

.clickable {
  cursor: pointer;
}

.small-input {
  width: 10rem;
}
.big-label > .ant-form-item-label {
  line-height: 1.5rem !important;
}
.multi-select-container {
  width: 100%;
}
.multi-select-container > label {
  position: relative;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ant-form-item {
  margin-top: 2px;
  margin-bottom: 2px;
}
.radio-style {
  height: 30px;
  line-height: 30px;
}
.radio-group{
  display: flex !important;
  flex-direction: column;
}