
// ######################################## //
// ## HEADER.SCSS ## //
// ######################################## //\

.header-section {
  background: $white;
  display: flex;
  flex-direction:row-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  height: 70px;

  // search input
  .search-input .ant-input {
    border-radius: 20px;
    padding: 4px 17px;
  }

  .ant-menu-horizontal {
    border: none;
    // border-left: 1px solid $gray-d;
    padding-left: 2rem;
    line-height: 32px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    .ant-dropdown-trigger {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-avatar {
        margin-right: 10px;
      }
    }
  }

  .ant-menu-item {
    padding: 0;
    margin-right: 30px;
    border: none;

    &:hover {
      border: none;
    }


    &.profile-item-menu {
      border-left: 1px solid $gray-d;
      padding-left: 2rem;
      margin-left: 0.5rem;
    }

    .ant-avatar {
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        margin: 0;
      }
    }

    // bagde-success
    .bagde-primary {

      .ant-badge-count {
        background: $theme-primary;
      }
    }

    .bagde-secondary {

      .ant-badge-count {
        background: $theme-secondary;
      }
    }
  }
}

// Dropdown menu sec
.profile-dropdown-sec {

  .ant-dropdown-menu-item {

    .anticon {
      margin-right: 8px;
    }
  }
}
