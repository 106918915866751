
// ######################################## //
// ## BUTTON.SCSS ## //
// ######################################## //

// Button Primary
.btn-primary {
  background-color: rgba($theme-primary, 1);
  height: 38px;
  font-size: 14px;
  padding: 0 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin: 1rem 0;
  transition: all 0.3s ease 0s;
  border: none;
  box-shadow: 0px 8px 20px rgba($theme-primary, 0.2);
  
  // hover style
  &:hover {
    background-color: darken($theme-primary, 50%);
  }
  
  // On mobile
  @include mobile {
    padding: 0.8rem 1rem;
  }
}

.btn-blue {
  background-color: rgba($theme-secondary, 1);
  height: 38px;
  font-size: 14px;
  padding: 0 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px;
  transition: all 0.3s ease 0s;
  border: none;
  box-shadow: 0px 8px 20px rgba($theme-secondary, 0.2);
  
  // hover style
  &:hover {
    background-color: darken($theme-secondary, 5%);
  }
  
  // On mobile
  @include mobile {
    padding: 0.8rem 1rem;
  }
}


// Button dark
.btn-dark {
  background-color: rgba($theme-primary-dark, 1);
  border: none;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  padding: 0.3rem 3rem;
  
  // On mobile
  @include mobile {
    padding: 0.8rem 1rem;
  }
  
  // hover style
  &:hover {
    background-color: rgba($theme-primary-dark, 0.9);
  }
}

// Button Primary
.btn-shadow-primary {
  max-width: 100%;
  height: 38px;
  font-size: 14px;
  padding: 0 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
  margin: 1rem 0;
  transition: all 0.3s ease 0s;
  box-shadow: 0px 8px 20px rgba($theme-secondary, 0.2);
  border: none;
  
  &:hover {
    // transform: translateY(-7px);
  }
}

.ant-btn-primary[disabled] {
  box-shadow: 0px 8px 20px rgba($theme-secondary, 0.2);

  &:hover {
    box-shadow: 0px 5px 10px rgba($theme-secondary, 0.2);
  }
}


.ant-btn-primary {

  &:focus {
    background-color: darken($theme-primary, 5%);
    border-color: darken($theme-primary, 5%);
  }
}


.ant-btn-light-blue {

  &:focus {
    background-color: darken($theme-primary, 5%);
    border-color: darken($theme-primary, 5%);
  }
}

.btn-margin {
  margin-bottom : 10px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;

}

.ant-btn-secondary {
    
  &:focus {
    background-color: darken($theme-secondary, 5%);
    border-color: darken($theme-secondary, 5%);
  }
}

.btn-icon {
  color:white
}
